
























































































import { mapActions, mapGetters } from "vuex";

import i18n from "@/plugins/i18n";
import CSV from "@/components/templates/CSV.vue";
import CopyButton from "@/components/templates/CopyButton.vue";
import * as views from "@/plugins/utils/View";
import * as crud from "@/plugins/utils/Crud";
import * as action from "@/store/actions";
import Vue from "vue";
import { Corp, Server, Trunk, View } from "@/types/conf";
import Top from "@/components/templates/Top.vue";
import TrunksDetail from "./TrunksDetail.vue";


export default Vue.extend({
  name: "Trunks",
  data: () => ({
    canDelete: false,
    canCreate: false,
    canUpdate: false,

    computedItems: screen.width < 600 ? 2 : 10,
    computedHeight: screen.width < 600 ? 48 * 12.2 : 48 * 11,

    alertType: "",
    alertMessage: "",
    trunkList: [] as Trunk[],
    corpList: [] as Corp[],
    serverList: [] as Server[],
    selectedItem: null as Trunk | null,
    search: "",
    dialog: false,
    dialogDelete: false,
    loadingList: false,

    actionList: [] as any[],
  }),

  computed: {
    ...mapGetters({
      views: "auth/views",
      user: "auth/user",
      hoverColor: "lookAndFeel/hoverColor",
      menuIconColor: "lookAndFeel/menuIconColor",
      menuFontColor: "lookAndFeel/menuFontColor",
    }),
    
    computedTrunkList() {
      let trunkCache: Trunk[] = this.trunkList;

      trunkCache = trunkCache.map(el => ({
        ...el,
        enabled: !!el.enabled ? i18n.t("misc.yes") : i18n.t("misc.no"),
        action: this.actionList.find(e => e.value == el.action)?.text,
        outgoing: !!el.outgoing ? i18n.t("misc.yes") : i18n.t("misc.no"),
        serIdName: this.serverList.find(e => e.id == el.serId)?.name
      })) as any;

      if (this.user.level == 1) {
        trunkCache = trunkCache.map((el, index) => ({
          ...el,
          action: this.trunkList[index].action,
        })) as any;
      }

      return trunkCache;
    }
  },

  async mounted() {
    this.loadingList = true;

    this.updateActionList();

    this.trunkList = await this.getTrunkList(action.TRUNK_CONTROLLER);

    this.corpList = await this.getCorpList(action.CORP_CONTROLLER);
    const arrCorpIds = [ ...this.corpList.map(el => ( { id: el.id, name: el.name } ))];

    this.serverList = await this.getServerList(action.SERVER_CONTROLLER);
    const arrSerIds = [ ...this.serverList.map(el => ( { id: el.id, name: el.name } ))];

    for (let i = 0; i < this.trunkList.length; i++) {

      for (let j = 0; j < arrCorpIds.length; j++) {
        if (arrCorpIds[j].id === this.trunkList[i].corpId) this.trunkList[i].corpIdName = arrCorpIds[j].name;
      }

      for (let j = 0; j < arrSerIds.length; j++) {
        if (arrSerIds[j].id === this.trunkList[i].serId) this.trunkList[i].serIdName = arrSerIds[j].name;
      }
    }

    this.actionHandler();
    this.loadingList = false;
  },

  components: {
    CSV,
    CopyButton,
    Top,
    TrunksDetail
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    '$i18n.locale': {
      handler: function(newLocale, oldLocale) {
        this.updateActionList();
      },
      immediate: true // Trigger the handler immediately on component mount
    },
  },

  methods: {
    ...mapActions({
      getTrunkList: "crudService/getList",
      getCorpList: "crudService/getList",
      getServerList: "crudService/getList",
      deleteTrunk: "crudService/deleteObject",
      getActions: "crudService/getDetail",
    }),
    headers() {
      return [
        {
          text: i18n.t("misc.actions"),
          value: "actions",
          sortable: false,
        },
        { 
          text: i18n.t("misc.num"),
          value: "number"
        },
        {
          text: i18n.t("misc.server"),
          value: "serIdName",
        },
        {
          text: i18n.t("configuration.corp"),
          value: "corpIdName",
        },
        {
          text: i18n.t("misc.enabled"),
          value: "enabled",
        },
        {
          text: i18n.t("misc.output"),
          value: "outgoing",
          align: "start",
        },
      ];
    },
    headersUser() {
      return [
        {
          text: i18n.t("misc.actions"),
          value: "actions",
          sortable: false,
        },
        {
          text: i18n.t("misc.num"),
          value: "number",
        },
        {
          text: i18n.t("misc.action"),
          value: "action",
        }
      ];
    },

    async updateActionList() {
      const actionList = await this.getActions({
        action: action.GET_ACTIONS,
        id: this.$store.state.auth.user.corpId,
      });

      const arr = [];

      arr.push({text: "No action", value:""});

      arr.push({text: i18n.t("lists.---HANG UP---"), value: "---HANG UP---"});
      arr.push( { text: "Hangup" , value: actionList.hangup }  );

      arr.push({text: i18n.t("lists.---TIMES---"), value: "---TIMES---"});
      for (const key in actionList.times) {
        arr.push({ text: key , value: actionList.times[key] } );
      }

      arr.push({text: i18n.t("lists.---IVRS---"), value: "---IVRS---"});
      for (const key in actionList.ivrs) {      
        arr.push( { text: key , value: actionList.ivrs[key] } );
      }

      arr.push({text: i18n.t("lists.---QUEUES---"), value: "---QUEUES---"});
      for (const key in actionList.queues) {
        arr.push( { text: key , value: actionList.queues[key] } );
      }
      
      arr.push({text: i18n.t("lists.---INTEGRATIONS---"), value: "---INTEGRATIONS---"});
      for (const key in actionList.integrations) {
        arr.push( { text: key , value: actionList.integrations[key] } );
      }

      arr.push({text: i18n.t("lists.---EXTENSIONS---"), value: "---EXTENSIONS---"});
      for (const key in actionList.extens) {
        arr.push({ text: key , value: actionList.extens[key] } );
      }
      this.actionList = arr;
    },

    actionHandler() {
      const view = this.views.find((v: View) => v.name == views.TRUNK); //view name
      if (view) {
        this.canDelete = view.actions.includes(crud.DELETE);
        this.canCreate = view.actions.includes(crud.CREATE);
        this.canUpdate = view.actions.includes(crud.UPDATE);
      }
    },

    deleteItem(item: Trunk) {
      this.selectedItem = item;
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      const response = await this.deleteTrunk({
        action: action.TRUNK_CONTROLLER,
        id: this.selectedItem?.id,
      });
      this.closeDelete();
      if (response) {
        this.alertType = "green darken-1";
        this.alertMessage = ((i18n.t("misc.deleted") as string) +
          i18n.t("misc.defaultSuccess")) as string;
        let itemListUpdated = [...this.trunkList];
        this.trunkList = itemListUpdated.filter(
          (trunk) => trunk.id != this.selectedItem?.id
        );
      } else {
        this.alertType = "error";
        this.alertMessage = i18n.t("misc.defaultError") as string;
      }
      this.$store.dispatch("snackbar/showSnack", {
        message: this.alertMessage,
        color: this.alertType,
        timeout: 3000,
      });
    },

    closeDelete() {
      this.dialogDelete = false;
    },

    async refreshList() {
      this.loadingList = true;
      this.corpList = await this.getCorpList(action.CORP_CONTROLLER);
      this.trunkList = await this.getTrunkList(action.TRUNK_CONTROLLER);

      const arrCorpIds = [
        ...this.corpList.map((el) => {
          return { id: el.id, name: el.name };
        }),
      ];
      this.serverList = await this.getServerList(action.SERVER_CONTROLLER);
      const arrSerIds = [
        ...this.serverList.map((el) => {
          return { id: el.id, name: el.name };
        }),
      ];

      for (let i = 0; i < this.trunkList.length; i++) {
        for (let j = 0; j < arrCorpIds.length; j++) {
          if (arrCorpIds[j].id === this.trunkList[i].corpId) {
            this.trunkList[i].corpIdName = arrCorpIds[j].name;
          }
        }

        for (let j = 0; j < arrSerIds.length; j++) {
          if (arrSerIds[j].id === this.trunkList[i].serId) {
            this.trunkList[i].serIdName = arrSerIds[j].name;
          }
        }
      }

      this.actionHandler();
      this.loadingList = false;
    },
  },
});
