




































import Vue from "vue";

import { mapActions, mapGetters } from "vuex";
import config from "../../config.js";
import i18n from "@/plugins/i18n";

import { CorpsSpends } from "@/types/conf";

export default Vue.extend({
  props: [],
  data: () => ({
    
    days: 0,
    order: "",

    corpsList: [] as any[],

    dialog: false,
    dialogDelete: false,
    loadingList: false,
  }),
  computed: {
    ...mapGetters({
      dashExtensions: "panel/dashExtensions",

      hoverColor: "lookAndFeel/hoverColor",
      menuIconColor: "lookAndFeel/menuIconColor",
      menuFontColor: "lookAndFeel/menuFontColor",
    }),
  },
  
  async mounted() {

    this.loadingList = true;

    this.corpsList = this.dashExtensions;

    this.loadingList = false;
  },

  watch: {
    dashExtensions(newVal, oldVal) {
      this.corpsList = newVal
    }
  },

  methods: {

    headers() {

      return [
        {
          text: i18n.t("configuration.corp"),
          value: "name",
        },
        {
          text: i18n.t("corpPanel.all"),
          value: "totalExt",
        },
        {
          text: i18n.t("misc.enabled"),
          value: "totalEnable"
        },
        {
          text: "Online",
          value: "totalOnline",
        },
      ];

    },
  },

});
