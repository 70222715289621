






































































































import Vue from 'vue';

import CSV from '@/components/templates/CSV.vue';
import CopyButton from '@/components/templates/CopyButton.vue';
import Top from '@/components/templates/Top.vue';
import QueueDetail from './QueueDetail.vue';
import QueueMOH from './QueueMOH.vue';

import { mapActions, mapGetters } from 'vuex';
import config from '../../../config.js';
import i18n from '@/plugins/i18n';

import * as views from '@/plugins/utils/View';
import * as crud from '@/plugins/utils/Crud';
import * as action from '@/store/actions';
import { Corp, Queue, Server, View } from '@/types/conf';
import msgCodes from '@/extendedFunctions/msgCodes';

export default Vue.extend({
  name: '',
  data: () => ({
    canDelete: false,
    canCreate: false,
    canUpdate: false,

    computedItems: screen.width < 600 ? 2 : 10,
    computedHeight: screen.width < 600 ? 48 * 12.2 : 48 * 11,

    alertType: '',
    alertMessage: '',
    queueList: [] as Queue[],
    corpList: [] as Corp[],
    serverList: [] as Server[],
    playingSound: [] as HTMLAudioElement[],
    stateIcons: [] as string[],
    selectedItem: null as Queue | null,
    search: '',
    dialog: false,
    dialogDelete: false,
    loadingList: false,
  }),
  computed: {
    ...mapGetters({
      views: 'auth/views',
      hoverColor: 'lookAndFeel/hoverColor',
      menuIconColor: 'lookAndFeel/menuIconColor',
      menuFontColor: 'lookAndFeel/menuFontColor',
    }),

    computedQueueList() {
      let queuesCache: Queue[] = this.queueList;

      queuesCache = queuesCache.map(el => ({
          ...el,
          strategy: i18n.t(`strategies.${el.strategy}`),
          position: el.position ? i18n.t('misc.yes') : i18n.t('misc.no'),
          ringinuse: el.ringinuse ? i18n.t('misc.yes') : i18n.t('misc.no'),
          seconds: el.seconds > 0 ? el.seconds : '-',
          timeout: el.strategy != "ringall" ? el.timeout : '-'
        })
      ) as any;
      
      return queuesCache;
    }
  },
  async mounted() {
    this.refreshList();
  },

  components: {
    CSV,
    CopyButton,
    Top,
    QueueDetail,
    QueueMOH,
  },

  methods: {
    ...mapActions({
      getAndSetQueues: "panel/getAndSetQueues",
      getQueueList: 'crudService/getList',
      getCorpList: 'crudService/getList',
      deleteQueue: 'crudService/deleteObject',
    }),
    headers() {
      return [
        {
          text: i18n.t('misc.actions'),
          value: 'actions',
          sortable: false,
          width: '125px',
        },
        {
          text: i18n.t('misc.name'),
          value: 'name',
        },
        {
          text: i18n.t('misc.strategy'),
          value: 'strategy',
        },
        {
          text: i18n.t('misc.strategyTimeout'),
          value: 'timeout',
        },
        {
          text: i18n.t('misc.position'),
          value: 'position',
        },
        {
          text: i18n.t('misc.adFrequency'),
          value: 'seconds',
        },
        {
          text: i18n.t('misc.ringInUse'),
          value: 'ringinuse',
        },
        {
          text: i18n.t('misc.audio'),
          value: 'sound',
          sortable: false,
          width: '90px',
        },
      ];
    },
    actionHandler() {
      const view = this.views.find((v: View) => v.name == views.QUEUE); //view name
      if (view) {
        this.canDelete = view.actions.includes(crud.DELETE);
        this.canCreate = view.actions.includes(crud.CREATE);
        this.canUpdate = view.actions.includes(crud.UPDATE);
      }
    },

    deleteItem(item: Queue) {
      this.selectedItem = item;
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      
      const response = await this.deleteQueue({
        action: action.QUEUE_CONTROLLER,
        id: this.selectedItem!.id
      });
      
      // This set if the form must persist or don't
      this.dialogDelete = response >= 400;

      this.alertMessage = msgCodes(response, false).alertMessage;
      this.alertType = msgCodes(response, false).alertType;

      this.$store.dispatch('snackbar/showSnack', {
        message: this.alertMessage,
        color: this.alertType,
        timeout: 3000,
      });

      this.refreshList();
    },

    //#region sound controls
    async downloadFile(item: Queue) {

      const file =item.corpId+"_"+ item.id;
      const url = config.url + 'queue/sounds/' + file;
      const token = localStorage.getItem('token');

      const response = await fetch(url, {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const blob = await response.blob();

      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = item.announce;
      link.click();
      URL.revokeObjectURL(link.href);
    },
    setPlay(item: Queue | any) {
      let index = this.queueList.findIndex((el: any) => el?.id == item.id); 
      let icon = this.stateIcons[index];

      if (icon === 'mdi-play') {
        icon = 'mdi-pause';
        this.playSound(item);
      } else {
        icon = 'mdi-play';
        this.pauseSound(item);
      }

      this.stateIcons[index] = icon;
    },
    async playSound(val: Queue) {
      const index = this.queueList.indexOf(val);
      const file =val.corpId+"_"+ val.id;
      const url = config.url + 'queue/sounds/' + file;
      const token = localStorage.getItem('token');
      const result = await fetch(url, {
        method: 'GET',
        credentials: 'same-origin',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const blob = await result.blob();
      if (blob) {
        const url = URL.createObjectURL(blob);
        this.playingSound[index] = new Audio(url);
        this.playingSound[index].addEventListener('ended', () => {
          this.stateIcons[index] = 'mdi-play';
        });
        this.playingSound[index].play();
        this.reloadList();
      } else {
        alert('Cannot load');
      }
    },
    pauseSound(val: Queue) {
      const index = this.queueList.indexOf(val);
      this.playingSound[index].pause();
      this.reloadList();
    },
    reloadList() {
      let itemListUpdated = [...this.queueList];
      this.queueList = itemListUpdated.filter((queue) => queue.id);
    },
    //#endregion

    async refreshList() {
      this.loadingList = true;
      this.corpList = await this.getCorpList(action.CORP_CONTROLLER);
      this.queueList = await this.getQueueList(action.QUEUE_CONTROLLER);
      await this.getAndSetQueues();

      if (this.queueList == undefined) {
        this.actionHandler();
        this.loadingList = false;
        return;
      }

      const arrCorpIds = [
        ...this.corpList.map((el) => ({ id: el.id, name: el.name })),
      ];

      for (let i = 0; i < this.queueList.length; i++) {
        this.stateIcons.push('mdi-play');
        for (let j = 0; j < arrCorpIds.length; j++) {
          if (arrCorpIds[j].id === this.queueList[i].corpId) {
            this.queueList[i].corpIdName = arrCorpIds[j].name;
          }
        }
      }
      this.playingSound.length = this.queueList.length;
      this.actionHandler();
      this.loadingList = false;
    },
  },
});
